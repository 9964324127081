import React from 'react';
import PropTypes, { elementType } from 'prop-types';

import { Table } from "antd";
import { CSVLink } from "react-csv";
import Tooltip from 'components/common/tooltip/Tooltip';

import ExportButton from '../export-button/ExportButton';
import Loader from '../loader/Loader';

import paginationRenderer from './config/paginationRenderer';

const TableComponent = ({
    keyField = 'id',
    loading = false,
    loadingMessage = 'Loading table...',
    data = null,
    columns = null,
    enableExport = false,
    enablePagination = false,
    defaultSorted = [],
    exportFileName = 'export.csv',
    idExportBtn = null, // This ID is used for testing automation
    selectRow = null,
    current = 1,
    showSizeChanger = false,
    pageSize = 5,
    ordering = "",
    name = ""
}) => {
    return (
        <div className='direct-mail-table'>                                            
            {loading ? (
                <Loader loadingMessage={loadingMessage} />
            ) : (                
                        <div>
                            {selectRow ? (

                                <Table                                
                                    columns={columns}
                                    loading={loading}
                                    className="tablebody"                                    
                                    dataSource={data}
                                    pagination={
                                        enablePagination ? {                        
                                        defaultPageSize: 5, 
                                        showSizeChanger: true, 
                                        pageSizeOptions: ['5', '10', '15']
                                        } : null
                                    }
                                    rowSelection={selectRow}                
                                />
                            ) : (
                                <Table
                                    columns={columns}
                                    loading={loading}
                                    className="tablebody"
                                    dataSource={data}
                                    pagination={
                                        enablePagination ? {                        
                                        defaultPageSize: 5, 
                                        showSizeChanger: true, 
                                        pageSizeOptions: ['5', '10', '15']
                                        } : null
                                    }         
                                />
                            )}
                            {data?.length && enableExport ? (
                                <div className='d-flex justify-content'>                                
                                    <CSVLink
                                        id="dashboard-table-export-btn"                                                                                                                        
                                        data={data}
                                        filename={exportFileName}
                                        headers={columns}                                        
                                    >
                                        <button            
                                            className='export-btn'
                                            >
                                            <div className='d-flex align-items-center justify-content-center'>
                                                Export
                                                {                                                    
                                                    <i className={`fas fa-file-export ml-2`}></i>
                                                }   
                                            </div>
                                        </button>
                                    </CSVLink>                                    
                                </div>
                            ) : null}
                        </div>
            )}
        </div>
    );
};

Table.propTypes = {
    keyField: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    loadingMessage: PropTypes.string,
    data: PropTypes.array,
    columns: PropTypes.array,
    enableExport: PropTypes.bool,
    enablePagination: PropTypes.bool,
    exportFileName: PropTypes.string,
    idExportBtn: PropTypes.string,
    selectRow: PropTypes.object,
};

export default TableComponent;
